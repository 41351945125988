import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VListGroup,{staticClass:"pl-0",attrs:{"prepend-icon":_vm.item.icon,"sub-group":_vm.subGroup,"no-action":"","flat":"","active-class":(" " + (_vm.isDark && _vm.getThemeMode.verticalSidebarDrawerColor != 'white'
        ? _vm.getThemeMode.verticalSidebarDrawerColor + ' darken-1 white--text'
        : 'grey lighten-4'))},scopedSlots:_vm._u([{key:"activator",fn:function(){return [(_vm.text)?_c(VListItemIcon,{staticClass:"v-list-item__icon--text",domProps:{"textContent":_vm._s(_vm.computedText)}}):_vm._e(),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"text-14",domProps:{"textContent":_vm._s(_vm.item.title)}})],1)]},proxy:true}]),model:{value:(_vm.listModel),callback:function ($$v) {_vm.listModel=$$v},expression:"listModel"}},[_vm._l((_vm.children),function(child,i){return [(child.children)?_c('base-item-sub-group',{key:("sub-group-" + i),staticClass:"pl-0",attrs:{"item":child}}):_c('base-item',{key:("item-" + i),attrs:{"item":child,"text":false}})]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }