import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'

// require more codemirror resource...

// you can set default global options and events when use

// // firebase sdk
// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: 'AIzaSyCyVMe1AdGqdcooD8DK7z6XfpoEM0yWUQc',
  authDomain: 'passive-vision.firebaseapp.com',
  projectId: 'passive-vision',
  storageBucket: 'passive-vision.appspot.com',
  messagingSenderId: '159460021375',
  appId: '1:159460021375:web:6902958b0ebdad80769b4a',
  measurementId: 'G-R4H192KPTM',
}
// Initialize Firebase
const app = initializeApp(firebaseConfig)
const firebaseAuth = getAuth(app)
const firebaseDb = getFirestore(app)

export {
  firebaseAuth,
  firebaseDb,
}
