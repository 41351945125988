import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import Ripple from 'vuetify/lib/directives/ripple';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VListItem,{directives:[{def: Ripple,name:"ripple",rawName:"v-ripple",value:({class: 'primary--text'}),expression:"{class: 'primary--text'}"}],staticClass:"text-18 pl-6",attrs:{"href":_vm.href,"rel":_vm.href && _vm.href !== '#' ? 'noopener' : undefined,"target":_vm.href && _vm.href !== '#' ? '_blank' : undefined,"to":_vm.item.to,"active-class":(" " + (_vm.isDark ? ' grey--text text--lighten-5' : 'primary--text')),"height":48}},[(_vm.text)?_c(VListItemIcon,{staticClass:"v-list-item__icon--text",domProps:{"textContent":_vm._s(_vm.computedText)}}):(_vm.item.icon)?_c(VListItemIcon,[_c(VIcon,{domProps:{"textContent":_vm._s(_vm.item.icon)}})],1):_vm._e(),(_vm.item.title || _vm.item.subtitle)?_c(VListItemContent,{staticClass:"pa-0 text-14",attrs:{"c":""}},[_c(VListItemTitle,{staticClass:"ma-0 text-14",domProps:{"textContent":_vm._s(_vm.item.title)}}),_c(VListItemSubtitle,{domProps:{"textContent":_vm._s(_vm.item.subtitle)}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }